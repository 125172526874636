<template>
	<div>
		<v-file-input
			:label="label"
			:hint="value"
			persistent-hint
			v-model="file"
			:clearable="false"
			append-icon="mdi-close"
			:append-outer-icon="allowSelect ? 'mdi-folder': ''"
			@click:append="clear"
			@click:append-outer="dialog = true"
		></v-file-input>
		<img class="mt-5" height="50px" :src="value"/>
		<mw-dialog
			v-model="dialog"
			cancel-btn
			submit-btn
			@submit="dialog = false"
		>
			<template v-slot:dialog-body>
				<v-list>
					<v-list-item
						v-for="f in files"
						:key="f.name"
						@click="$emit('input', f.url)"
					>	
						<v-list-item-content>
							<v-list-item-title>
								{{ f.name }}
							</v-list-item-title>
						</v-list-item-content>
						<v-list-item-icon><img height="30px" :src="f.url"/></v-list-item-icon>
					</v-list-item>
				</v-list>
			</template>
		</mw-dialog>
	</div>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
import { uploadFileToStorage, listFiles } from "@/assets/storageFunctions.js";
export default {
	name: "UploadField",
	props: {
		location: { type: String, default: "uploads" },
		label: { type: String },
		value: { type: [String, Object] },
		allowSelect: {type: Boolean, default: true}
	},
	data: () => {
		return {
			file: null,
			dialog: false,
			files: [],
		};
	},
	computed: {
		filePath() {
			if (!this.file) {
				return "";
			}
			var filePath = this.file.name;
			filePath = this.location + "/" + filePath;
			return filePath;
		},
	},
	watch: {
		file() {
			this.uploadFile();
		},
		dialog(v) {
			if (v) {
				const self = this;
				listFiles(self.location).then((files) => {
					files.items.forEach((file) => {
						file.getDownloadURL().then((url) => {
							self.files.push({ name: file.name, url });
						});
					});
				});
			}
		},
	},
	components: {
		MwDialog,
	},
	methods: {
		clear() {
			this.$emit("input", null);
		},
		uploadFile() {
			const self = this;

			if (!self.file) {
				return false;
			}
			self.loading = true;
			uploadFileToStorage(self.file, self.filePath).then((file) => {
				if (file) {
					self.$root.$emit("showToast", "Uploaded");
					self.$emit("complete", file);
					file.ref.getDownloadURL().then((url) => {
						self.$emit("input", url);
					});
				}
			});
		},
	},
};
</script>
