export default {
	name: {
		value: "name",
		display: true,
		label: "Name",
	},
	title: {
		value: "title",
		display: true,
		label: "Title",
		component: "v-text-field",
	},
	html: {
		value: "html",
		display: true,
		label: "HTML",
	},
	live_content: {
		value: "live_content",
		display: true,
		multiple: true,
		default: [],
		component: "v-select",
		require_id: true,
		label: "Live Content",
		items: ["client", "participant", "contract_manager"],
	},
	promote_content: {
		value: "promote_content",
		display: true,
		label: "Promote Content",
		require_id: true,
		default: null,
	},
};
