<template>
	<v-sheet flat color="transparent">
		<template v-for="field in fields">
			<!-- if the field has a named slot -->
			<slot
				v-if="haveSlot(field.value)"
				:name="field.value"
				:data="doc"
			></slot>
			<!-- otherwise parse as standard -->
			<mw-form-field
				v-else
				@input="(v) => (doc[field.value] = v)"
				:values="doc"
				:field="field.value"
				:key="field.value"
				v-bind="model[field.value]"
			></mw-form-field>
		</template>
		<slot name="append"></slot>
		<p class="text-right mt-5">
			<v-btn color="primary" text @click="save">Save</v-btn>
		</p>
		<v-dialog v-model="error" width="360">
			<v-card>
				<v-card-title>{{ $t("errors.error") }}</v-card-title>
				<v-card-text>
					{{ $t("errors.not_found") }}
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						router-link
						color="primary"
						:to="`/${path || storeModule}/new`"
						>{{ $t("buttons.new") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import MwFormField from "@c/ui/MwFormField";

export default {
	name: "Edit",
	props: {
		id: { type: String }, // the id of the document
		titleField: { type: String }, // the title of the page
		path: { type: String }, // where the path is different to the store module
		storeModule: { type: String }, // the storeModule id
		embedded: { type: Boolean, default: false }, // if a form is embedded it emits the ID instead of redirecting to another page
		prefill: { type: Object }, //  key/value pairs of data to prefill into the form where required
		model: { type: Object }, // @doc the data model of the object - for generating fields, labels and default values
	},
	data: () => {
		return {
			error: false,
		};
	},
	components: {
		MwFormField,
	},
	computed: {
		fields() {
			var self = this;
			// @doc retrieves only fields you want to display
			let fields = self.mwutils.getFields(this.model);
			if (self.id) {
				return fields;
			}
			return fields.filter((field) => {
				return !field.require_id;
			});
		},
		doc() {
			// @doc retrieves the object from the database or a new empty document
			let doc = this.$store.state[this.storeModule].data[this.id];
			//;
			return doc || this.mwutils.getEmpty(this.model);
		},
		title(){
			return this.titleField ? this.doc[this.titleField] : null;
		}
	},
	watch: {
		id(value) {
			if (!value) {
				this.error = false;
			}
		},
		title: {
			immediate: true, 
			handler(v) {
				if( v ){
					this.setTitle();
				}
			}
		}
	},
	methods: {
		haveSlot(key) {
			return typeof this.$scopedSlots[key] !== "undefined";
		},

		new() {
			const self = this;
			self.$store
				.dispatch(`${self.storeModule}/new`, self.doc)
				.then((id) => {
					if (self.embedded) {
						self.$root.$emit("showToast", "Saved");
					} else {
						self.$router.replace(
							`/${self.path || self.storeModule}/${id}`
						);
					}
				});
		},
		update() {
			const self = this;
			self.$store.dispatch(`${self.storeModule}/patch`, self.doc);

			self.$root.$emit("showToast", "Saved");
		},
		save() {
			const self = this;
			if (self.id) {
				self.update();
			} else {
				self.new();
			}
		},
		setTitle() {
			this.$store.commit("SET_TITLE", this.title  );
		},
	},
	created() {
		const self = this;
		if (self.id) {
			self.$store
				.dispatch(`${self.storeModule}/fetchById`, self.id)
				.catch(() => {
					self.error = true;
				});
		}
	},
};
//
</script>
"
