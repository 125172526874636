<template>
	<v-sheet flat>
		<mw-edit
			:id="id"
			storeModule="content"
			:model="model"
			@saved="$emit('saved')"
		>
			<template v-slot:name="{ data }" v-if="!id">
				<v-text-field
					v-model="data.name"
					label="The content id - leave blank to autogenerate"
					hint="No spaces or special characters"
					permanent-hint
				></v-text-field>
			</template>

			<template v-slot:promote_content="{ data }">
				<mw-date-picker v-model="data.promote_content" label="Promote Until" format="DD/MM/YYYY" picker-type="date" ></mw-date-picker>
			</template>
			<template v-slot:html="{ data }">
				<mw-editor v-model="data.html"></mw-editor>
			</template>
		</mw-edit>
	</v-sheet>
</template>

<style lang="less">
.ql-editor {
	max-height: 600px;
	margin-bottom: 20px;
}
</style>

<script type="text/javascript">
import Content from "@/models/Content";
import MwEdit from "@c/ui/MwEdit";
import MwEditor from "@c/ui/MwEditor";
import MwDatePicker from "@c/ui/MwDatePicker";

export default {
	name: "EditContent",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	computed: {
		model() {
			return Content;
		},
	},
	components: {
		MwEdit,
		MwEditor,
		MwDatePicker
	},
};
</script>
