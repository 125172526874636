<template>
	<v-card flat color="transparent" width="100%">
		<v-autocomplete
			:label="label"
			clearable
			:multiple="multiple"
			outlined
			:items="items"
			:value="value"
			@input="select"
			:append-outer-icon="addNewButton ? 'mdi-plus-circle' : ''"
			@click:append-outer="newInNewTab"
			@focus="fetchAllOptions"
		>
		</v-autocomplete>
	</v-card>
</template>

<script type="text/javascript">
import Vuex from "vuex";

export default {
	name: "MwSelect",
	props: {
		value: { type: [String, Array] }, // @doc the intial value
		storeModule: { type: String }, // the module of the store to select from and add to
		newPath: { type: String },
		include: { type: Array }, // an array of IDs to include
		exclude: { type: Array }, // an array of IDs to exclude,
		label: { type: String }, //
		addNewButton: { type: Boolean, default: false },
		multiple: { type: Boolean, default: false }, // whether to allow multiple answers
		itemText: { type: String, default: "title" }, // the key to use for the item label
		defaultValues: { type: Object }, // any default values to include with the newly added object
	},
	data: () => {
		return {
			latest: "",
			all: null,
		};
	},
	computed: {
		...Vuex.mapState({
			collection(state) {
				return state[this.storeModule].data;
			},
		}),
		items() {
			const self = this;
			let items = this.mwutils.itemsArray(self.collection, this.itemText);
			if (self.include && self.include.length) {
				items = items.filter((a) => self.include.includes(a.value));
			}
			if (self.exclude && self.exclude.length) {
				items = items.filter((a) => !self.exclude.includes(a.value));
			}
			return items;
		},
	},
	created() {
		if (this.value) {
			let v = this.value;
			if (typeof this.value == "string") {
				v = [this.value];
			}
			this.$store.dispatch(`${this.storeModule}/fetchByIds`, v);
		}
	},
	methods: {
		fetchAllOptions() {
			if (!this.all) {
				this.$store.dispatch(`${this.storeModule}/fetchAndAdd`,  {clauses: {limit: -1}});
				this.all = true;
			}
		},
		newInNewTab() {
			let r = this.$router.resolve({ name: this.newPath });
			window.open(r.href, "_blank");
		},
		select(selected) {
			// if (!selected) {
			// 	return;
			// }
			this.$emit("input", selected);
		},
	},
};
//
</script>
"
