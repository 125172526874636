<template>
	<span>
		<v-component
			:is="component"
			:label="label"
			v-model="value"
			:values="values"
			@change="v => $emit('input', v)" 
			@input="v => $emit('input', v)" 
			:items="['v-select', 'v-autocomplete', 'v-combobox'].includes(component) ? items : false"
			v-bind="options"
			:multiple="multiple"
		></v-component>
	</span>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Vue from "vue";

import { VTextField, VCheckbox, VTextarea, VSelect, VColorPicker, VAutocomplete, VCombobox } from "vuetify/lib";

import MwSelect from "@c/ui/MwSelect";
import MwUpload from "@c/storage/MwUpload";
Vue.component("VTextField", VTextField);
Vue.component("VCombobox", VCombobox);
Vue.component("VAutocomplete", VAutocomplete);
Vue.component("VCheckbox", VCheckbox);
Vue.component("VTextarea", VTextarea);
Vue.component("VSelect", VSelect);
Vue.component("VColorPicker", VColorPicker); 

export default {
	name: "GeneralField",
	props: {
		component: {type: String},
		label: {type: String},
		options: {type: [Object, Array]},
		items: {type: [Object, Array]},
		field: {type: String },
		values: { type: Object },
		multiple: {type: Boolean},
		// copyFrom: {type: String},
	},
	data: () => {
		return {
			value: "",
		};
	},
	watch: {
		values: {
			deep: true,
			immediate: true, 
			handler(v) {
				if( v ){
					this.value = v[this.field];
					// if( this.copyFrom ){
					// 	if( !this.value ){
					// 		this.value = v[this.copyFrom];
					// 	}
					// }
				}

			},
		},
		data: {
			handler() {
				if (this.value !== this.data) {
					this.value = this.data;
				}
			},
		}
	},
	components: {
		MwSelect, MwUpload
	}
};
//
</script>
"
